import Cookies from 'universal-cookie';

export const getLsAccessToken = () => {
  return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;
};

export const updateLsAccessToken = (newToken: string) => {
  localStorage.removeItem('accessToken');
  localStorage.setItem('accessToken', newToken);
};

// const removeAuth0DefaultTokens = () => {
//   const keys = Object.keys(localStorage);
//   const auth0StoredKeys = keys.filter((key) => key.includes('auth0spajs'));
//   auth0StoredKeys?.map((auth0DefaultLocalStorageKey) => {
//     localStorage.removeItem(auth0DefaultLocalStorageKey);
//   });
// };
export const deleteLsBothTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  // removeAuth0DefaultTokens();
};
export const setLsBothTokens = (access: string, refresh: string) => {
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
};
export const getRefreshTokenFromLSAuth0 = (keyName: string) => {
  let refreshTokenFromAuth0Ls = '';
  const keys = Object.keys(localStorage);
  const auth0StoredKeys = keys.filter((key) => key.includes(keyName));

  auth0StoredKeys?.map((localStorageKey) => {
    const auth0Ls: any = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

    const isRefreshTokenExist = auth0Ls?.body?.refresh_token;

    if (typeof isRefreshTokenExist !== 'undefined') {
      refreshTokenFromAuth0Ls = isRefreshTokenExist;
      return;
    }
  });
  return refreshTokenFromAuth0Ls;
};
export const getSidFromLSAuth0 = (keyName: string) => {
  let refreshTokenFromAuth0Ls = '';
  const keys = Object.keys(localStorage);
  const auth0StoredKeys = keys.filter((key) => key.includes(keyName));

  auth0StoredKeys?.map((localStorageKey) => {
    const auth0Ls: any = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

    const isSidExist = auth0Ls?.decodedToken?.claims?.sid;

    if (typeof isSidExist !== 'undefined') {
      refreshTokenFromAuth0Ls = isSidExist;
      return;
    }
  });
  return refreshTokenFromAuth0Ls;
};

type UserRoleObjectType = {
  federated: boolean;
  userId: string;
  organizationId: string;
  currentOrganization: {
    id: string;
    name: string;
  };
};
export const setLsUserRoleObject = (userRoleObject: UserRoleObjectType) => {
  localStorage.setItem('userRole', JSON.stringify(userRoleObject));
};
export const getLsUserRoleObject = () => {
  return JSON.parse(localStorage.getItem('userRole') || '{}');
};
export const deleteLsUserRoleObject = () => {
  localStorage.removeItem('userRole');
};

export const setLsFeaturesEnrollmentPlanner = (data: boolean) => {
  localStorage.setItem('featuresEnrollmentPlanner', JSON.stringify(data));
};
export const getLsFeaturesEnrollmentPlanner = () => {
  return String(localStorage.getItem('featuresEnrollmentPlanner')) === 'true';
};
export const deleteLsFeaturesEnrollmentPlanner = () => {
  localStorage.removeItem('featuresEnrollmentPlanner');
};

export const setClientsFiltersObject = (data: unknown) => {
  localStorage.setItem('clientsFilters', JSON.stringify(data));
};
export const getClientsFiltersObject = () => {
  return JSON.parse(localStorage.getItem('clientsFilters') || '[]');
};
export const resetClientListPagination = () => {
  localStorage.removeItem('lastPage');
};

export const setCurrentNoteID = (data: unknown) => {
  localStorage.setItem('currentNoteID', JSON.stringify(data));
};
export const getCurrentNote = () => {
  return JSON.parse(localStorage.getItem('currentNoteID') || '{}');
};
export const removeCurrentNote = () => {
  localStorage.removeItem('currentNoteID');
};

export const setRoadmapsFiltersObject = (data: unknown) => {
  localStorage.setItem('roadmapsFilters', JSON.stringify(data));
};
export const getRoadmapsFiltersObject = () => {
  return JSON.parse(localStorage.getItem('roadmapsFilters') || '[]');
};

export const setAutoLogoutRedirectUrl = (redirectTo: string | null) => {
  if (redirectTo === null) {
    localStorage.removeItem('redirectTo');
  } else if (redirectTo !== '') {
    localStorage.setItem('redirectTo', redirectTo);
  }
};
export const getAutoLogoutRedirectUrl = () => {
  return localStorage.getItem('redirectTo');
};

export const setUserActiveCookie = (timeSeconds: number) => {
  const cookies = new Cookies(null, { path: '/', maxAge: timeSeconds });
  cookies.set('isUserActive', 'true');
  return true;
};
export const getUserActiveCookie = () => {
  try {
    const cookies = new Cookies(null, { path: '/' });
    const isUserActive = cookies.get('isUserActive');

    if (isUserActive) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const setInviteLinkMetadata = (link: string) => {
  localStorage.setItem('linkMetadata', link);
};
export const getInviteLinkMetadata = () => {
  return localStorage.getItem('linkMetadata');
};

type ReportingFiltersTypeSingle = {
  programId: string | null;
  chwId: string | null;
  startDate: string | null;
  endDate: string | null;
  typeParent: string | null;
};

type ReportingFiltersType = {
  programId: string[] | null;
  chwId: string[] | null;
  startDate: string | null;
  endDate: string | null;
  typeParent: string | null;
};

export const setReportingFilters = (data: ReportingFiltersType) => {
  localStorage.setItem('reportingFilters', JSON.stringify(data));
};

export const getReportingFilters = (): ReportingFiltersType => {
  return JSON.parse(localStorage.getItem('reportingFilters') || '{}');
};

export const setReportingFiltersSingle = (data: ReportingFiltersTypeSingle) => {
  localStorage.setItem('reportingFilters', JSON.stringify(data));
};

export const getReportingFiltersSingle = (): ReportingFiltersTypeSingle => {
  return JSON.parse(localStorage.getItem('reportingFilters') || '{}');
};
export const deleteReportingFilters = () => localStorage.removeItem('reportingFilters');

export const setIsDatadogEnabled = (link: string | boolean) => {
  localStorage.setItem('datadogEnabled', String(link));
};
export const getIsDatadogEnabled = () => {
  return localStorage.getItem('datadogEnabled') === 'true' || false;
};
export const setDatadogUserId = (id: string) => {
  localStorage.setItem('datadogUserId', id);
};
export const getDatadogUserId = () => {
  return localStorage.getItem('datadogUserId');
};
