import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  postHealthTracker,
  getHealthTrackerCategory,
  postHealthTrackerCategory,
  patchHealthTrackerCategory,
  getHealthTracker
} from './healthTrackerActions';

export type SourceType = 'MEDICAL_RECORD' | 'IN_PERSON_MEASUREMENT' | 'SELF_REPORTED';
export type DirectionType = 'DECREASE' | 'INCREASE';

export type HealthTrackerCategoryItem = {
  id: number;
  name: string;
  unit: string;
  direction: SourceType;
  primaryMetric: boolean;
  hasEntries: boolean;
};

export type HealthTrackerMetricListItem = {
  id: number;
  entryDate: string;
  entryTime: string;
  value: number;
  source: SourceType;
};

type InitialState = {
  healthTrackerCategoryList: HealthTrackerCategoryItem[];
  healthTrackerMetricList: HealthTrackerMetricListItem[];
};

const initialState: InitialState = {
  healthTrackerCategoryList: [],
  healthTrackerMetricList: []
};

export const healthTrackerSlice = createSlice({
  name: 'healthTrackerSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHealthTracker.fulfilled, (state, action) => {
        state.healthTrackerMetricList = action.payload;
      })
      .addCase(getHealthTracker.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postHealthTracker.fulfilled, (_, { payload }: any) => {
        notifyUserSuccess('Your value was saved.');
      })
      .addCase(postHealthTracker.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getHealthTrackerCategory.fulfilled, (state, action) => {
        state.healthTrackerCategoryList = action.payload;
      })
      .addCase(getHealthTrackerCategory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postHealthTrackerCategory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(patchHealthTrackerCategory.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export default healthTrackerSlice.reducer;
