// import { datadogRum } from '@datadog/browser-rum';
// import { getDatadogUserId } from 'modules/networkTools/localStorageTokens';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FullStory as FS, init as initFullStory } from '@fullstory/browser';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const setFSIdentity = ({
  hash,
  name,
  userId,
  orgId
}: {
  hash: string;
  name: string;
  userId: string;
  orgId: string;
}) => {
  FS('setIdentity', {
    uid: hash,
    properties: {
      displayName: name,
      userId,
      orgId
    }
  });
};

const FeAppMonitoring: React.FunctionComponent = ({}) => {
  const [searchParams] = useSearchParams();
  const aqa = searchParams.get('aqa');
  const isAqaMode = aqa === 'true';
  const { enableFullStory } = useFlags();

  // const setupDatadog = () => {
  //   const applicationId = String(process.env.REACT_APP_DATADOG_APP_ID);
  //   const clientToken = String(process.env.REACT_APP_DATADOG_CLIENT_TOKEN);
  //   const env = String(process.env.REACT_APP_ENVIRONMENT);
  //
  //   datadogRum.init({
  //     applicationId: applicationId,
  //     clientToken: clientToken,
  //     site: 'datadoghq.com',
  //     env: env,
  //     sessionSampleRate: 100,
  //     sessionReplaySampleRate: 0,
  //     trackUserInteractions: true,
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask'
  //   });
  //   const datadogUserId = getDatadogUserId();
  //   if (datadogUserId) {
  //     datadogRum.setUser({
  //       id: datadogUserId,
  //       name: 'Impact user'
  //     });
  //   }
  // };
  React.useEffect(() => {
    // const datadogEnabled = getIsDatadogEnabled();
    if (enableFullStory && !isAqaMode) {
      initFullStory({ orgId: 'o-228SR9-na1' });

      // setupDatadog();
    }
  }, []);

  return null;
};

export default FeAppMonitoring;
