import {
  TableBody,
  TableCell,
  TableContainer,
  Table as TableMaterial,
  TableRow
} from '@mui/material';
import { Button, LabelComponent, DatePicker } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC } from 'react';
import { putEnrollmentNotConfigured } from 'store/enrollment/enrollmentActions';
import { EnrollmentNotConfiguredDTO } from 'store/enrollment/enrollmentSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as Yup from 'yup';

import style from './goalModal.module.scss';

interface Props {
  program: EnrollmentNotConfiguredDTO;
  forwardStep: () => void;
  handleClose: () => void;
}

const FormGoalDate: FC<Props> = ({ program, forwardStep, handleClose }) => {
  const dispatch = useAppDispatch();
  const { trackerBar } = useAppSelector((state) => state.enrollment);

  const onSubmit = async (val: any) => {
    if (val.startDate !== 'Invalid Date') {
      return dispatch(putEnrollmentNotConfigured({ startDate: val.startDate || null }));
    }
  };

  const { values, handleSubmit, errors, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...program,
      startDate: program?.startDate
    },
    validationSchema: Yup.object({
      startDate: Yup.string()
        .nullable()
        .matches(/^\d{4}-\d{2}-\d{2}$/, '\n' + 'Date is not valid.')
    }),
    onSubmit
  });

  const now = dayjs();
  const showDateExpired = trackerBar && dayjs(trackerBar.endDate).isBefore(now, 'day');

  return (
    <div className={style.content}>
      <form>
        <div className={style.title}>
          <div className={style.titleText}>
            {showDateExpired
              ? `Congratulations! You’ve closed out the year and enrolled ${trackerBar?.actualCaseload} people. 🎉`
              : ' Pick a start date'}
            {showDateExpired && <p>It’s time to update your goals for the new year.</p>}
          </div>
          <div
            className={style.closeIcon}
            onClick={() => {
              onSubmit(values);
              handleClose();
            }}
          >
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className={style.inputItem}>
          <LabelComponent text="Tell us when your enrollment year starts:" />
          <DatePicker
            onChange={(date: any) => {
              setFieldValue('startDate', date === null ? null : dayjs(date).format('YYYY-MM-DD'));
            }}
            value={values.startDate}
            defaultValue={program?.startDate}
            notValidateOnClear
            placeholder="MM/DD/YYYY"
          />
          {errors.startDate ? <div className={style.fieldError}>{errors.startDate}</div> : null}
          <div className={style.helpText}>This start date will apply to all of your programs.</div>
        </div>
        {program?.history && program?.history.length > 0 && (
          <div className={style.history}>
            <div className={style.historyTitle}>Date history:</div>
            <TableContainer>
              <TableMaterial>
                <TableBody>
                  {program.history.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        Enrollment start date:{' '}
                        <div className={style.counter}>
                          {item.startDate ? dayjs(item.startDate).format('MM/DD/YYYY') : 'Cleared'}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={style.info}>
                          <div className={style.date}>
                            {item.createdAt && dayjs(item.createdAt).format('MM/DD/YYYY')}
                          </div>
                          <div className={style.subName}>{item.user}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableMaterial>
            </TableContainer>
          </div>
        )}
      </form>
      <div className={style.buttonsWrap}>
        <Button
          handleOnClick={(e) => {
            handleSubmit(e);
            forwardStep();
          }}
          type="submit"
          theme="secondary"
          icon={<span className="material-icons">arrow_forward_ios</span>}
        />
      </div>
    </div>
  );
};

export default FormGoalDate;
